export const users = [
    'p2pmodels.user1',
    'p2pmodels.user2',
    'p2pmodels.user3',
    'p2pmodels.user4',
    'p2pmodels.user5',
    'p2pmodels.user6',
]

const mockVideos = [
    {
        id: 'MsVoQB3DTAdZ',
        video_type: 'K',
        primary_audio_language_code: 'en',
        title: 'Keyboard - 10822',
        description: '',
        duration: 12,
        thumbnail:
            'https://userdata.staging.amara.org/video/thumbnail/bf119bf3f4d730b5c53256f6894e45e78d1cb56d_480x270_crop-smart_upscale-True_q85.jpg',
        created: '2018-04-16T15:19:24Z',
        team: 'collab-demo-team',
        project: null,
        all_urls: [
            'http://cdnapi.kaltura.com/p/1492321/sp/149232100/playManifest/entryId/0_hvfq3mvs/format/url/protocol/http',
        ],
        metadata: {},
        languages: [
            {
                code: 'de',
                name: 'German',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/de/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/de/',
            },
            {
                code: 'en',
                name: 'English',
                published: true,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/en/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/en/',
            },
            {
                code: 'es',
                name: 'Spanish',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/es/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/es/',
            },
            {
                code: 'fr',
                name: 'French',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/fr/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/fr/',
            },
            {
                code: 'pt-br',
                name: 'Portuguese, Brazilian',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/pt-br/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/pt-br/',
            },
        ],
        activity_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/activity/',
        urls_uri: 'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/urls/',
        subtitle_languages_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/',
        resource_uri: 'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/',
    },
    {
        id: 'mjgA8CA8ieBU',
        video_type: 'K',
        primary_audio_language_code: 'en',
        title: 'Footage - 12127',
        description: '',
        duration: 7,
        thumbnail:
            'https://userdata.staging.amara.org/video/thumbnail/3d303d684fabfb10cdf4a996e00e1745205a9e68_480x270_crop-smart_upscale-True_q85.jpg',
        created: '2018-04-16T15:19:24Z',
        team: 'collab-demo-team',
        project: null,
        all_urls: [
            'http://cdnapi.kaltura.com/p/1492321/sp/149232100/playManifest/entryId/0_0xivs8to/format/url/protocol/http',
        ],
        metadata: {},
        languages: [
            {
                code: 'de',
                name: 'German',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/de/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/de/',
            },
            {
                code: 'en',
                name: 'English',
                published: true,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/en/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/en/',
            },
            {
                code: 'es',
                name: 'Spanish',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/es/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/es/',
            },
            {
                code: 'fr',
                name: 'French',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/fr/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/fr/',
            },
            {
                code: 'pt-br',
                name: 'Portuguese, Brazilian',
                published: false,
                dir: 'ltr',
                subtitles_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/pt-br/subtitles/',
                resource_uri:
                    'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/pt-br/',
            },
        ],
        activity_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/activity/',
        urls_uri: 'https://staging.amara.org/api/videos/mjgA8CA8ieBU/urls/',
        subtitle_languages_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/',
        resource_uri: 'https://staging.amara.org/api/videos/mjgA8CA8ieBU/',
    },
]

export const videoRegistry = mockVideos.reduce((registry, v) => {
    registry.set(v.id, v)
    return registry
}, new Map())

export const mockTasks = [
    {
        job_id: 'JS4TMAF',
        video: 'MsVoQB3DTAdZ',
        language: 'de',
        source_team: 'collab-demo-team',
        team: 'collab-demo-team',
        evaluation_teams: [],
        status: 'in-progress',
        work_status: 'needs-subtitler',
        subtitler: null,
        reviewer: null,
        approver: null,
        created: '2018-10-29T22:40:28Z',
        subtitles_due_date: null,
        review_due_date: null,
        approval_due_date: null,
        due_date: null,
        completed: null,
        work_completed: null,
        video_uri: 'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/',
        subtitles_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/de/subtitles/',
        actions_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/de/subtitles/actions/',
        resource_uri:
            'https://staging.amara.org/api/teams/collab-demo-team/subtitle-requests/JS4TMAF/',
    },
    {
        job_id: '0UTJG1S',
        video: 'MsVoQB3DTAdZ',
        language: 'fr',
        source_team: 'collab-demo-team',
        team: 'collab-demo-team',
        evaluation_teams: [],
        status: 'in-progress',
        work_status: 'needs-subtitler',
        subtitler: null,
        reviewer: null,
        approver: null,
        created: '2018-10-29T22:40:28Z',
        subtitles_due_date: null,
        review_due_date: null,
        approval_due_date: null,
        due_date: null,
        completed: null,
        work_completed: null,
        video_uri: 'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/',
        subtitles_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/fr/subtitles/',
        actions_uri:
            'https://staging.amara.org/api/videos/MsVoQB3DTAdZ/languages/fr/subtitles/actions/',
        resource_uri:
            'https://staging.amara.org/api/teams/collab-demo-team/subtitle-requests/0UTJG1S/',
    },
    {
        job_id: '9AR30EW',
        video: 'mjgA8CA8ieBU',
        language: 'es',
        source_team: 'collab-demo-team',
        team: 'collab-demo-team',
        evaluation_teams: [],
        status: 'in-progress',
        work_status: 'needs-subtitler',
        subtitler: null,
        reviewer: null,
        approver: null,
        created: '2018-10-29T22:40:28Z',
        subtitles_due_date: null,
        review_due_date: null,
        approval_due_date: null,
        due_date: null,
        completed: null,
        work_completed: null,
        video_uri: 'https://staging.amara.org/api/videos/mjgA8CA8ieBU/',
        subtitles_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/es/subtitles/',
        actions_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/es/subtitles/actions/',
        resource_uri:
            'https://staging.amara.org/api/teams/collab-demo-team/subtitle-requests/9AR30EW/',
    },
    {
        job_id: 'TNEYKA0',
        video: 'mjgA8CA8ieBU',
        language: 'de',
        source_team: 'collab-demo-team',
        team: 'collab-demo-team',
        evaluation_teams: [],
        status: 'in-progress',
        work_status: 'needs-subtitler',
        subtitler: null,
        reviewer: null,
        approver: null,
        created: '2018-10-29T22:40:28Z',
        subtitles_due_date: null,
        review_due_date: null,
        approval_due_date: null,
        due_date: null,
        completed: null,
        work_completed: null,
        video_uri: 'https://staging.amara.org/api/videos/mjgA8CA8ieBU/',
        subtitles_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/de/subtitles/',
        actions_uri:
            'https://staging.amara.org/api/videos/mjgA8CA8ieBU/languages/de/subtitles/actions/',
        resource_uri:
            'https://staging.amara.org/api/teams/collab-demo-team/subtitle-requests/TNEYKA0/',
    },
]

export const REALLOCATION_TIME = 90 // (s)
